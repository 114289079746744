import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as Dialog from "@radix-ui/react-dialog"
import * as Form from "@radix-ui/react-form"
import cx from "classnames"
import * as React from "react"
import { useState } from "react"

import { StoreContext } from "../context/store-context"
import Cart from "./cart"

function CheckoutCompleteModal() {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [isMessageSent, setIsMessageSent] = useState(false)

  const { loading, checkout, createNewCheckout } =
    React.useContext(StoreContext)

  function handleCloseModal() {
    createNewCheckout()
  }

  async function handleSubmit(event) {
    let data = Object.fromEntries(new FormData(event.currentTarget))
    event.preventDefault()
    setIsFormSubmitted(true)

    const orderIdRegex = /Order\/(\d+)(\?|$)/
    const match = checkout.order.id.match(orderIdRegex)

    if (match) {
      data[
        "order-link"
      ] = `https://admin.shopify.com/store/rockaburger/orders/${match[1]}`
    }

    if (process.env.NODE_ENV === "development") {
      setIsMessageSent(true)
      return
    }
    await fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(data).toString(),
    })
      .then(() => {
        setIsMessageSent(true)
      })
      .catch(error => alert(error))
  }

  return (
    <Dialog.Root
      open={checkout.completedAt}
      onInteractOutside={handleCloseModal}
    >
      <Dialog.Portal>
        <Dialog.Overlay className="bg-white/70 data-[state=open]:animate-overlayShow fixed inset-0 z-30" />
        <Dialog.Content className="overflow-y-auto flex flex-col gap-2 data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-xl bg-white p-[25px] shadow-2xl focus:outline-none z-40 ">
          <Dialog.Title className="m-0 text-2xl">Order Completed!</Dialog.Title>
          <Dialog.Description className="text-mauve11 mt-[10px] text-[15px] leading-normal">
            Your order has been sent to us. Please check your inbox for an email
            confirmation.
          </Dialog.Description>
          <div className="max-h-150px overflow-y-auto">
            <Cart readOnly />
          </div>

          <Form.Root
            className="my-5"
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
            name="feedback"
            onSubmit={handleSubmit}
          >
            <p className="text-grey mb-1">
              Have some feedback? Leave a message below.
            </p>
            <input type="hidden" name="form-name" value="feedback" />
            <input
              type="hidden"
              name="order-number"
              value={checkout?.order?.orderNumber}
            />
            <div className="grid">
              <Form.Field className="grid gap-3" name="message">
                <Form.Message
                  className="text-sm text-primary"
                  match="valueMissing"
                >
                  Please fill in the field below
                </Form.Message>
                <Form.Control asChild>
                  <textarea
                    className="w-full bg-white p-2 rounded border disabled:bg-grey-light"
                    type="text"
                    placeholder="How did we do?"
                    required
                    disabled={isMessageSent || isFormSubmitted}
                  />
                </Form.Control>
              </Form.Field>
            </div>
            <Form.Submit asChild>
              <button
                disabled={isMessageSent || isFormSubmitted}
                className={cx(
                  "mt-1 px-4 py-1 rounded-md active:outline float-right font-display inline-block",
                  {
                    "bg-grey-light": !isMessageSent && !isFormSubmitted,
                    "bg-green text-white": isMessageSent && isFormSubmitted,
                  }
                )}
              >
                {isMessageSent || isFormSubmitted ? "Feedback Sent" : "Send"}
                {isMessageSent && isFormSubmitted && (
                  <FontAwesomeIcon icon={faCheck} className="ml-2" />
                )}
              </button>
            </Form.Submit>
          </Form.Root>
          <div className="grid gap-2 font-display">
            <a
              href="https://account.rockaburger.co.uk"
              className="bg-primary text-white px-4 py-1 rounded-md active:outline text-center"
            >
              View Order
            </a>
            <button
              onClick={handleCloseModal}
              disabled={loading}
              className="bg-grey-light px-4 py-1 rounded-md active:outline disabled:text-white disabled:cursor-wait"
            >
              Start New Order
            </button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default CheckoutCompleteModal
