import * as Sentry from "@sentry/react"

const dsn = process.env.GATSBY_SENTRY_DSN // Replace with your actual Sentry DSN

Sentry.init({
  dsn,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0, // Set the desired tracing sample rate
  enabled: process.env.GATSBY_ENVIRONMENT !== "local",
  environment: process.env.GATSBY_ENVIRONMENT,
})
