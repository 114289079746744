import {
  faBars,
  faInfoCircle,
  faShoppingCart,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import cx from "classnames"
import { navigate } from "gatsby"
import * as React from "react"
import { Sticky } from "react-sticky"

import { StoreContext } from "../context/store-context"
import LogoTextInitials from "../images/svg/logo-initials.svg"
import LogoTextOnly from "../images/svg/logo-text-only.svg"
import Cart from "./cart"
import MenuList from "./menu-list"

const Nav = () => {
  const {
    loading,
    checkout,
    toggleMobileNav,
    mobileNavOpen,
    onCartOpenChange,
  } = React.useContext(StoreContext)

  return (
    <Sticky topOffset={60}>
      {({ style, isSticky }) => (
        <nav
          id="top-nav-bar"
          style={{ ...style }}
          className={cx(
            "bg-white flex items-center justify-between z-30 transition-all ease-in-out duration-75 uppercase text-md h-[65px] w-full",
            {
              "bg-white shadow-lg": isSticky || mobileNavOpen,
            }
          )}
        >
          <div
            id="nav-left"
            className="flex items-center text-2xl overflow-hidden h-full"
          >
            <div className="md:hidden">
              <button
                onClick={() => toggleMobileNav()}
                className="text-center text-primary flex items-center justify-center p-1 rounded-md ml-2 cursor-pointer"
              >
                <FontAwesomeIcon
                  fixedWidth
                  icon={mobileNavOpen ? faTimes : faBars}
                />
              </button>
            </div>

            <LogoTextInitials
              className={cx(
                "hidden md:inline-block w-[40px] cursor-pointer  ml-4 fill-primary"
              )}
              onClick={() => navigate("/")}
            />
            <LogoTextOnly
              className={cx(
                "w-[200px] lg:w-[250px] lg:inline-block cursor-pointer ml-4"
              )}
              onClick={() => navigate("/")}
            />

            {/* <div
              id="store-location"
              className="hidden text-base px-3 py-2 bg-grey-light lg:inline-block rounded-lg"
            >
              <FontAwesomeIcon
                icon={faLocationDot}
                className="mr-2 text-primary"
              />
              <span>Ladypool Road</span>
            </div> */}
          </div>
          <div
            id="nav-right"
            className="flex items-center justify-start gap-5 h-full"
          >
            <div className="hidden md:block h-100 felx items-center">
              {<MenuList />}
            </div>
            <DropdownMenu.Root
              onOpenChange={onCartOpenChange}
              className="felx items-center"
            >
              <DropdownMenu.Trigger className="relative flex items-center text-2xl h-full px-3 radix-state-open:bg-offwhite text-secondary">
                <FontAwesomeIcon icon={faShoppingCart} />
                {checkout.lineItems.length > 0 && (
                  <div className="absolute top-[12px] right-[5px] w-4 h-4 text-center text-white rounded-full bg-primary leading-none flex items-center justify-center text-[10px]">
                    {checkout.lineItems.length}
                  </div>
                )}
              </DropdownMenu.Trigger>
              <DropdownMenu.Portal className="relative">
                <DropdownMenu.Content
                  side="bottom"
                  align="end"
                  sideOffset={0}
                  alignOffset={0}
                  className="bg-offwhite rounded-bl-md lg:w-[400px] lg:h-auto w-screen h-[calc(100dvh-65px)] overflow-hidden shadow-2xl z-30 flex flex-col"
                >
                  <DropdownMenu.Label className="font-display text-primary text-xl p-3">
                    Your Cart
                  </DropdownMenu.Label>
                  {!checkout.lineItems.length && (
                    <div className="p-3">
                      <h5 className="bg-grey-light text-grey text-center p-5">
                        Your Cart is empty
                      </h5>
                    </div>
                  )}

                  <>
                    <Cart />
                    <div className="flex flex-col gap-2 p-4 shadow-lg shadow-black">
                      {checkout?.discountApplications?.length > 0 && (
                        <div className="grid grid-cols-2 text-sm">
                          <h5>Subtotal:</h5>
                          <div className="text-right">
                            {Intl.NumberFormat("en-GB", {
                              style: "currency",
                              currency: "GBP",
                            }).format(checkout.lineItemsSubtotalPrice?.amount)}
                          </div>
                        </div>
                      )}
                      {checkout?.discountApplications?.map((d, i) => (
                        <div key={i} className="grid grid-cols-[auto,auto]">
                          <div className="text-xs">
                            <span className="px-1 rounded-sm">{d.title}</span>
                          </div>
                          <div className="text-right text-xs text-green">
                            -
                            {Intl.NumberFormat("en-GB", {
                              style: "currency",
                              currency: "GBP",
                            }).format(
                              (d.value.percentage / 100) *
                                checkout.lineItemsSubtotalPrice?.amount
                            )}
                          </div>
                        </div>
                      ))}
                      <div className="grid grid-cols-2 text-lg">
                        <h4 className="uppercase">Total:</h4>
                        <div className="text-right">
                          {Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                          }).format(checkout.totalPriceV2?.amount)}
                        </div>
                      </div>
                      {checkout.totalPriceV2?.amount < 30 && (
                        <div className="text-orange text-sm">
                          {" "}
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="mr-1"
                          />
                          Min order £30 for free local delivery
                        </div>
                      )}
                      {checkout.lineItems.length > 0 && (
                        <a
                          disabled={loading || checkout.lineItems.length === 0}
                          href={checkout.webUrl}
                          target="_self"
                          className="uppercase bg-primary p-3 w-full rounded-md text-white active:outline text-center"
                        >
                          Checkout
                        </a>
                      )}
                      <DropdownMenu.Item className="lg:hidden uppercase bg-grey p-3 w-full rounded-md text-white active:outline text-center cursor-pointer">
                        Back To Menu
                      </DropdownMenu.Item>
                    </div>
                  </>
                </DropdownMenu.Content>
              </DropdownMenu.Portal>
            </DropdownMenu.Root>
          </div>
        </nav>
      )}
    </Sticky>
  )
}

export default Nav
