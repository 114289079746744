import { faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"
import * as React from "react"
import { useContext } from "react"
import Initials from "../images/svg/logo-initials.svg"

import { StoreContext } from "../context/store-context"
import { trackAction } from "../utils/track"

const Cart = ({ readOnly }) => {
  const { loading, checkout, updateLineItem, removeLineItem } =
    useContext(StoreContext)

  function updateQuantity(item, modifier) {
    const quantity = item.quantity
    if (modifier === "add") {
      updateLineItem(item.id, quantity + 1)
      trackAction(`Add Quantity id:${item.id}`, quantity + 1)
    }
    if (modifier === "subtract") {
      updateLineItem(item.id, quantity - 1)
      trackAction(`Remove Quantity id:${item.id}`, quantity - 1)
    }
  }

  function removeItem(item) {
    removeLineItem(item.id)
  }

  function renderVariantTitle(item) {
    if (item.variant?.title === "Default Title") {
      return null
    } else {
      return item.variant?.title
    }
  }

  return (
    <div
      className={cx(
        "flex-1 flex flex-col gap-2 lg:max-h-[50dvh] overflow-auto p-4",
        {
          "select-none pointer-events-none opacity-60": loading,
        }
      )}
    >
      {checkout.lineItems.map(item => (
        <div
          key={item.id}
          className={cx("grid grid-cols-[50px,4fr,2fr] gap-2 p-2 rounded-md", {
            "bg-white": !readOnly,
            "bg-offwhite": readOnly,
          })}
        >
          <div className="flex items-center justify-center">
            {item.variant?.image?.src ? (
              <img src={item?.variant?.image?.src} className="w-10" />
            ) : (
              <Initials className=" w-10 fill-grey-light" />
            )}
          </div>
          <div>
            <div className="text-sm text-primary font-display capitalize">
              {item.title}
            </div>
            {renderVariantTitle(item) && (
              <div className="text-xs text-grey">
                {renderVariantTitle(item)}
              </div>
            )}
            {item.customAttributes.map(a => (
              <div className="text-xs text-grey">
                {a.key}: {a.value}
              </div>
            ))}
            {!readOnly && (
              <button
                className="text-xs text-primary active:opacity-80"
                onClick={() => removeItem(item)}
              >
                <FontAwesomeIcon icon={faTrashCan} className="mr-1" />
                remove
              </button>
            )}
          </div>
          {!readOnly && (
            <div className="flex items-center flex-col justify-center">
              <div>
                {" "}
                {Intl.NumberFormat("en-GB", {
                  style: "currency",
                  currency: "GBP",
                }).format(item?.variant?.priceV2?.amount * item.quantity)}
              </div>
              <div className="grid grid-cols-3 gap-1">
                <button
                  disabled={item.quantity === 1}
                  className="flex items-center justify-center text-grey rounded-full w-5 h-5 active:outline focus:outline disabled:opacity-60"
                  onClick={() => updateQuantity(item, "subtract")}
                >
                  -
                </button>
                <div className="text-black text-center">{item.quantity}</div>
                <button
                  className="flex items-center justify-center text-grey rounded-full w-5 h-5 active:outline focus:outline"
                  onClick={() => updateQuantity(item, "add")}
                >
                  +
                </button>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default Cart
