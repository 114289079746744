import cx from "classnames"
import * as React from "react"

import { StoreContext } from "../context/store-context"

function AddToCart({
  item,
  className,
  quantity,
  onAddToCart,
  children,
  ...props
}) {
  const { addVariantToCart, loading } = React.useContext(StoreContext)

  function addToCart(e) {
    e.preventDefault()
    addVariantToCart(item, quantity)
    onAddToCart()
  }

  return (
    <button
      type="submit"
      className={cx(" disabled:cursor-not-allowed", className)}
      onClick={addToCart}
      disabled={loading || props.disabled}
      {...props}
    >
      {children}
    </button>
  )
}

export default AddToCart
