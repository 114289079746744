import "./src/styles/global.css"
import "react-loading-skeleton/dist/skeleton.css"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/scrollbar"

import * as React from "react"

import Layout from "./src/components/layout"
import { MenuModalProvider } from "./src/context/menu-modal-context"
import { StoreProvider } from "./src/context/store-context"
import { ErrorBoundary } from "@sentry/react"

import "./sentry"

// Wrap the entire Gatsby app with a context provider
export const wrapRootElement = ({ element, props }) => (
  <ErrorBoundary>
    <StoreProvider>
      <MenuModalProvider>{element}</MenuModalProvider>
    </StoreProvider>
  </ErrorBoundary>
)

// Wrap each page element with a layout component
export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
)

// Add an event listener when a route updates
export const onRouteUpdate = ({ location }) => {
  console.log("Route updated to", location.pathname)
}
