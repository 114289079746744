export function trackAction(name, value) {
  if (typeof window !== undefined && window?.gtag) {
    window.gtag("event", name, { value })
  }
}

export function trackClick(value) {
  if (typeof window !== undefined && window?.gtag) {
    window.gtag("event", "Click", { value })
  }
}
