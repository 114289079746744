import {
  faInstagram,
  faLinkedin,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as Form from "@radix-ui/react-form"
import { Link } from "gatsby"
import * as React from "react"
import { useState } from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"

import Logo from "../images/svg/logo-initials.svg"

const socials = [
  {
    title: "Instagram",
    link: "https://www.instagram.com/loverockaburger/",
    icon: faInstagram,
  },
  {
    title: "YouTube",
    link: "https://www.youtube.com/@rockaburgermedia",
    icon: faYoutube,
  },
  {
    title: "TikTok",
    link: "https://www.tiktok.com/@loverockaburger",
    icon: faTiktok,
  },
  {
    title: "LinkedIn",
    link: "https://www.linkedin.com/in/rockaburger-ltd-10b167298/",
    icon: faLinkedin,
  },
]
const information = [
  { title: "About Us", link: "/about" },
  { title: "Terms Of Use", link: "/terms" },
  { title: "Privacy Policy", link: "/privacy" },
]

const Footer = () => {
  const [isSubscribed, setIsSubscribed] = useState(false)

  async function handleSubmit(event) {
    const data = Object.fromEntries(new FormData(event.currentTarget))
    event.preventDefault()
    setIsSubscribed(true)

    if (process.env.NODE_ENV === "development") {
      localStorage.setItem("userSubscribed", "true")
      setIsSubscribed(true)
      return
    }
    await fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(data).toString(),
    }).catch(error => alert(error))
  }
  const renderSectionTitle = title => (
    <h3 className="text-xl uppercase text-primary">{title}</h3>
  )
  return (
    <div className="bg-white grid gap-5 lg:gap-2 p-5 lg:p-10 grid-cols-1  lg:grid-cols-[250px,1fr,1fr,1fr] h-full [&>div]">
      <div className="hidden lg:grid gap-4">
        <div>
          <Logo className="w-[150px] fill-primary" />
        </div>
      </div>
      <div className="flex flex-col gap-5">
        {renderSectionTitle("Our Socials")}
        <ul className="text-lg">
          {socials.map((s, i) => (
            <li key={i}>
              <OutboundLink
                href={s.link}
                target="_blank"
                className="inline-flex items-center py-2 gap-2 hover:text-primary"
              >
                <FontAwesomeIcon icon={s.icon} fixedWidth />
                <div className="flex-1">{s.title}</div>
              </OutboundLink>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex flex-col gap-5">
        {renderSectionTitle("Information")}
        <ul className="text-lg">
          {information.map((s, i) => (
            <li key={i}>
              <Link
                to={s.link}
                className="inline-flex items-center py-2 gap-2 hover:text-primary"
              >
                {s.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex flex-col gap-5">
        <div>
          {renderSectionTitle("Subscribe")}
          <p className="text-xs mt-2 text-grey">
            Subscribe and be the first one to hear about our latest offers
          </p>
        </div>

        <Form.Root
          className="font-sans grid gap-2"
          name="subscribe"
          method="post"
          netlify-honeypot="bot-field"
          data-netlify="true"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="subscribe" />
          {!isSubscribed && (
            <div className="grid grid-cols-2 gap-2">
              <Form.Field className="grid gap-3" name="firstname">
                <div className="flex flex-col justify-between"></div>
                <Form.Control asChild>
                  <input
                    className="box-border w-full h-fit bg-grey-light p-4 rounded-md shadow-black inline-flex items-center justify-center leading-none text-white outline-none data-[invalid=true]:outline-primary"
                    type="text"
                    placeholder="FULL NAME"
                    required
                  />
                </Form.Control>
              </Form.Field>
              <Form.Field className="grid gap-3" name="email">
                <div className="flex flex-col justify-between"></div>
                <Form.Control asChild>
                  <input
                    className="box-border w-full h-fit bg-grey-light p-4 rounded-md shadow-black inline-flex items-center justify-center leading-none text-white outline-none data-[invalid=true]:outline-primary"
                    type="email"
                    placeholder="EMAIL"
                    required
                  />
                </Form.Control>
              </Form.Field>
            </div>
          )}
          {isSubscribed && (
            <div className="bg-secondary text-black rounded-md p-2">
              <FontAwesomeIcon
                icon={faCheckCircle}
                fixedWidth
                className=" w-28"
              />{" "}
              Welcome to the family! Keep an eye out in your email for our
              latest offers.
            </div>
          )}
          <Form.Submit asChild>
            <button
              disabled={isSubscribed}
              className="uppercase bg-primary p-3 w-full rounded-md text-white active:outline disabled:bg-grey-light disabled:text-grey-lighter"
            >
              Subscribe Now
            </button>
          </Form.Submit>
        </Form.Root>
      </div>
    </div>
  )
}

export default Footer
