import {
  faEnvelope,
  faInfoCircle,
  faNewspaper,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import * as React from "react"

const MenuList = ({ onClick }) => {
  const buttonClassName =
    "font-display px-2 py-1 min-w-[100px] rounded text-center uppercase cursor-pointer active:bg-primary active:text-white transition-colors ease-in-out duration-150 text-primary"
  return (
    <div className="grid md:grid-cols-3 gap-4 w-full">
      <Link
        to="/"
        className={buttonClassName}
        activeClassName="bg-primary text-white"
        onClick={onClick}
      >
        <FontAwesomeIcon icon={faNewspaper} className="mr-2" />
        Menu
      </Link>
      <Link
        to="/about"
        className={buttonClassName}
        activeClassName="bg-primary text-white"
        onClick={onClick}
      >
        <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
        About
      </Link>
      <Link
        to="/contact"
        className={buttonClassName}
        activeClassName="bg-primary text-white"
        onClick={onClick}
      >
        <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
        Contact
      </Link>
    </div>
  )
}

export default MenuList
